:root {
  --purple: #4e5a5b; /* 짙은녹색, 변수명 바꾸는 리소스 감축을 위해 purple이면 짙은 */
  --black: #000000;
  --dark-gray: #d9d9d94d; /*연한회색으로 바뀜 */
  --white: #ffffff;
  --light-gray: #909090;
  --ambient-white: #d7d7d7;
  --light-green: #afb7b9; /*배경색에 주로 쓰이는 연녹색 */
  --overlay-fill: rgba(217, 217, 217, 0.3); /* #D9D9D9 with 30% opacity */
}

@font-face {
  font-family: "godorounded";
  src: url("../public/fonts/godoRoundedR.woff2") format("woff2"),
    url("../public/fonts/godoRoundedR.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "godo";
  src: url("../public/fonts/GodoM.woff2") format("woff2"),
    url("../public/fonts/GodoM.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "KoddiUDOnGothic";
  src: url("../public/fonts/KoddiUDOnGothic-Regular.woff2") format("woff2"),
    url("../public/fonts/KoddiUDOnGothic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "godorounded", "godo", "KoddiUDOnGothic", sans-serif;
}
